import React, { useRef, useEffect } from 'react'

import {
  Space,
  CardWrapper,
  CardFront,
  CardBack,
  Title,
  Description,
} from './styles'
import './styles.css'

const Card = ({ title, frontBackgroundImage, children }) => {
  const cardWrapper = useRef(null)

  useEffect(() => {
    cardWrapper.current.addEventListener('click', () => {
      cardWrapper.current.classList.toggle('is-flipped')
    })
  })

  return (
    <Space>
      <CardWrapper ref={cardWrapper}>
        <CardFront frontBackgroundImage={frontBackgroundImage}>
          <Title>{title}</Title>
        </CardFront>
        <CardBack>
          <Description>{children}</Description>
        </CardBack>
      </CardWrapper>
    </Space>
  )
}

export default Card
