import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import Logo from './Logo'
import { HeroImage, HeroRightSide, CaptionBackground } from './styles'
import { useWindowDimensions, useDate } from '../../utils/hooks'
import { breakpoints, space } from '../../utils/theme'
import { Container, Text } from '../../utils/styles'

const Hero = () => {
  const { width } = useWindowDimensions()
  const hours = useDate().getHours()
  const [time, setTime] = useState('afternoon')

  useEffect(() => {
    if (hours >= 6 && hours <= 8) {
      setTime('sunrise')
    } else if (hours >= 9 && hours <= 12) {
      //time = 'morning'
      setTime('noon')
    } else if (hours >= 12 && hours <= 14) {
      setTime('noon')
    } else if (hours >= 15 && hours <= 17) {
      setTime('afternoon')
    } else if (hours >= 18 && hours <= 20) {
      setTime('evening')
    } else if (hours >= 21) {
      setTime('night')
    } else {
      setTime('afternoon')
    }
  }, [hours])

  console.log(time)

  return (
    <>
      <HeroImage
        src={`/hero/${time}.png`}
        style={{ position: 'relative' }}
        alt="Weisgerbergasse"
      />
      <Container>
        <HeroRightSide>
          <CaptionBackground>
            <Logo
              onClick={() => {
                navigate('/')
              }}
              color="white"
              height={width > breakpoints.s ? space[8] : space[6]}
            />
            <Text>
              Geöffnet Sa-So @ 12-18
              <br />
              Geschlossen Mo-Fr
              <br />
              <br /> Weißgerbergasse 28,
              <br /> 90403 Nürnberg
              <br />
              <br />
              <a
                href="https://marktschwaermer.de/de-DE/assemblies/11935"
                rel="noreferrer"
                target="_blank"
              >
                an weiteren Adressen... →
              </a>
              <br />
              <a href="#locations">...und online →</a>
            </Text>
          </CaptionBackground>
        </HeroRightSide>
      </Container>
    </>
  )
}

export default Hero
