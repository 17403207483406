import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import Hero from '../components/Hero'
import PhotoGrid from '../components/PhotoGrid'
import Footer from '../components/Footer'
import { Container, ThreeThirdsGrid, H2, H3, Text } from '../utils/styles'
import { TextWrapper, Grid } from '../pageStyles/index'
import { space, colors, breakpoints } from '../utils/theme'
import { useWindowDimensions } from '../utils/hooks'

const IndexPage = () => {
  const { width } = useWindowDimensions()

  return (
    <>
      <Hero />
      <Container>
        <Grid
          gap={width > breakpoints.l ? space[12] : space[6]}
          style={{ marginTop: space[20] }}
        >
          <StaticImage
            src="../images/jose.jpg"
            layout="fullWidth"
            alt="Josée"
            loading="lazy"
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 7,
              gridRowStart: 2,
              gridRowEnd: 6,
            }}
          />
          <TextWrapper
            style={{
              gridRowStart: 1,
              gridColumnStart: 7,
              gridRowEnd: 5,
              gridColumnEnd: 13,
            }}
          >
            <H2>Unsere Geschichte</H2>
            <Text>
              Hallo - ich bin Josée. Ich bin Frankokanadierin und mit einem
              Finnen verheiratet, der 2007 aus beruflichen Gründen von Montreal
              nach Nürnberg zog. Wir haben manchmal darüber nachgedacht
              zurückzuziehen, aber irgendwie hat uns Nürnberg bis heute nicht
              mehr losgelassen!
              <br />
              <br /> Also hier bin ich und mache Eiscrème! Ich fühle mich stolz
              und glücklich, dass im Jahr 2015 die kleine Eismanufaktur in einem
              charaktervollen historischen Haus der schönsten Gasse Nürnbergs
              eröffnen konnte.
            </Text>
          </TextWrapper>
          <StaticImage
            src="../images/creative.jpg"
            layout="fullWidth"
            alt="Creative Icecream"
            loading="lazy"
            style={{
              gridRowStart: 5,
              gridColumnStart: 7,
              gridRowEnd: 11,
              gridColumnEnd: 13,
            }}
          />
          <TextWrapper
            style={{
              gridRowStart: 6,
              gridColumnStart: 1,
              gridRowEnd: 12,
              gridColumnEnd: 7,
            }}
          >
            <H2>Kreativer Eisgenuss</H2>
            <Text>
              Wir kreieren manchmal Eiscreme-Leckereien nach, die es in dem
              Eckladen meiner Kindheitstage in Québec gab, wie zB eine Kugel Eis
              zwischen 2 weichen gebackenen cookies, was in Nordamerika "Ice
              Cream Sandwich" heißt. Ebenso lieben wir die Macarons Glacés nach
              französischer Art: Eis zwischen knusprigen Macarons, die Fräulein
              Gusti, eine Chef Patissière nur wenige Gehminuten von uns
              entfernt, für uns zaubert.
              <br />
              <br />
              Auch das bekanntere Eis am Stil bietet viel Spaß beim
              Experimentieren in der Küche, wenn es darum geht, den richtigen
              Schokoladenüberzug mit gehackten Nüssen oder Beerenstückchen zum
              cremigen Inneren zu finden, auch in veganen Varianten.
              <br />
              <br />
              Wir fühlen uns immer geehrt, ein Teil Eurer magischen Momente zu
              sein, sei es nun mit einem gigantischen Macaron Glacé zum Teilen
              mit Euren Liebsten oder eine Eistorte mit knuspriger Praline zum
              Geburtstag.
              <br />
              <br />
              Mit Leidenschaft bereiten wir in unserer Küche all die Toppings,
              Saucen, Nuss- und Schokoladencremes und vieles mehr zu, die in
              unsere Eisbecher oder andere Kreationen kommen. Wie z.B. das
              Nougat im Nougat-Eis, die Praline die in gefrorenen Torten steckt,
              die Fruchtcoulis zum Verwirbeln im Eis, sowie die Nusspaste und
              Sahne für Eiskreationen.
            </Text>
          </TextWrapper>

          <StaticImage
            src="../images/zutaten.jpg"
            layout="fullWidth"
            alt="Zutaten"
            loading="lazy"
            style={{
              gridRowStart: 12,
              gridColumnStart: 1,
              gridRowEnd: 17,
              gridColumnEnd: 7,
            }}
          />
          <TextWrapper
            style={{
              gridRowStart: 11,
              gridColumnStart: 7,
              gridRowEnd: 17,
              gridColumnEnd: 13,
            }}
          >
            <H2>Unsere Zutaten und Verfeinerungen</H2>
            <Text>
              Seit unseren Anfängen stellen wir Eis aus ehrlichen Zutaten her,
              die möglichst bio oder fairtrade zertifiziert sind. Wir schätzen
              die Kooperation mit weiteren lokalen Erzeugern und beziehen zum
              Beispiel unsere Biomilch von Bauernhof Kleinlein nur 20 Minuten
              von unserer Küche entfernt. Wir lieben es Eisliebhaber mit
              raffinierten Geschmacksrichtungen zu überraschen, etwa durch die
              Verwendung von besonderen Zuckerarten wie Ahorn Sirup,
              Rohrohrzucker oder karamellisierten Zucker, oder durch die Zugabe
              einer Prise Kräuter oder Gewürze. Unser Schokoladeneis ist kein
              gewöhnliches Schokoladeneis, sondern ein Sinneserlebnis der
              besonderen Art!
              <br />
              <br />
              Und wer sagt, dass veganes Eis langweilig sein muss? Unsere vegane
              Eissorten sind allseits beliebt und die veganen Sorten Dunkle
              Schokolade, Stardust – Geröstete Mandeln, und Kokos Chaos mit
              Mango haben einen festen Platz unter den beliebtesten
              Geschmackssorten in unserer Theke erlangt.
              <br />
              <br />
              Wir lieben es auch unsere eigenen Frucht- oder Beerencoulis
              herzustellen, und Cookies für Krümel im Eis oder für die Kreation
              von Ice Cream Sandwiches zu backen.
            </Text>
          </TextWrapper>

          <StaticImage
            src="../images/IMG_9387_Edit.jpg"
            layout="fullWidth"
            alt="Kathrin"
            loading="lazy"
            style={{
              gridRowStart: 17,
              gridColumnStart: 7,
              gridRowEnd: 21,
              gridColumnEnd: 13,
            }}
          />
          <TextWrapper
            style={{
              gridRowStart: 17,
              gridColumnStart: 1,
              gridRowEnd: 21,
              gridColumnEnd: 7,
            }}
          >
            <H2>Nachhaltigkeit</H2>
            <Text>
              Als wir mit der Eisherstellung begannen, waren wir fassungslos
              über die schiere Menge an Kartons, Plastikverpackungen und
              -eimern, Holzpaletten, Eisbechern und Plastiklöffeln, die
              zwangsläufig anfielen. Auch wenn es für einen kleinen Hersteller
              ein langer und gewundener Weg ist, haben wir seitdem nach
              Möglichkeiten gesucht, den Verpackungsmüll zu reduzieren, indem
              wir die richtigen Zutaten auswählen und mit den Lieferanten
              zusammenarbeiten.
              <br />
              <br />
              Schon früh haben wir in unserem Laden Probierlöffel aus Metall
              eingeführt und sind auf kompostierbare Becher und Löffel
              umgestiegen. Unser abgepacktes Eis ist seit 2017 in
              wiederverwendbaren Gläsern in unseren Partnercafés und seit kurzem
              auch online erhältlich.
            </Text>
          </TextWrapper>

          <StaticImage
            src="../images/events.jpg"
            layout="fullWidth"
            alt="Events"
            loading="lazy"
            style={{
              gridRowStart: 21,
              gridColumnStart: 1,
              gridRowEnd: 24,
              gridColumnEnd: 7,
            }}
          />
          <TextWrapper
            style={{
              gridRowStart: 21,
              gridColumnStart: 7,
              gridRowEnd: 24,
              gridColumnEnd: 13,
            }}
          >
            <H2>Kleine oder große Veranstaltungen</H2>
            <Text>
              Wir kennen uns mit Veranstaltungen aus, seien es intime, private
              Familientreffen, mehrtägige Ausstellungen mit Tausenden von
              Teilnehmern oder Firmenveranstaltungen für Hunderte von Menschen
              an einem Strand ohne Strom.
            </Text>
            <Link to="kontakt">Kontaktiert uns!</Link>
          </TextWrapper>
        </Grid>

        <PhotoGrid />

        <TextWrapper
          style={{
            textAlign: 'center',
            backgroundColor: colors.backgroundColor,
          }}
          id="locations"
          as="section"
        >
          <h1>Unser Eis gibt es an diesen Adressen:</h1>
          <iframe
            src="https://snazzymaps.com/embed/307299"
            style={{ width: '100%', height: '400px', border: 'none' }}
            title="Map"
          ></iframe>

          <ThreeThirdsGrid>
            <div>
              <H3>Die Kleine Eismanufaktur</H3>
              <address>
                Weißgerbergasse 28,
                <br /> 90403 Nürnberg
              </address>
              <p>
                Hier könnt ihr Eis zum direkt verzehren kaufen oder verpackt zum
                mitnehmen für Zuhause.
              </p>
            </div>

            <div>
              <H3>Fräulein Gusti</H3>
              <address>
                Obere Schmiedgasse 52,
                <br /> 90403 Nürnberg
              </address>
              <p>Hier könnt ihr Eis zum direkt verzehren kaufen.</p>
            </div>

            <div>
              <H3>Tante Noris am See</H3>
              <address>
                Johann-Soergel-Weg
                <br /> 90489 Nürnberg
              </address>
              <p>Hier könnt ihr Eis zum direkt verzehren kaufen.</p>
            </div>
          </ThreeThirdsGrid>
        </TextWrapper>

        <TextWrapper style={{ marginTop: space[20] }}>
          <H2>und Online bei Marktschwärmer</H2>
          <p>
            Marktschwärmer ist ein ganz besonderer Wochenmarkt: Du bestellst
            deine Lebensmittel ganz einfach online und holst sie einmal pro
            Woche in der Schwärmerei in deiner Nachbarschaft ab. Dabei triffst
            Du die Menschen, die sie hergestellt haben.
          </p>
          <a
            href="https://marktschwaermer.de/de-DE/"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../images/marktschwärmer-logo.png"
              layout="constrained"
              loading="lazy"
              style={{
                width: '250px',
                marginTop: space[8],
                marginBottom: space[8],
              }}
            />
          </a>
          <ThreeThirdsGrid>
            <a
              href="https://marktschwaermer.de/de-DE/assemblies/11935"
              target="_blank"
              rel="noreferrer"
            >
              Nürnberg Eberhardshof {`  `}
            </a>
            <a
              href="https://marktschwaermer.de/de-DE/assemblies/12599"
              target="_blank"
              rel="noreferrer"
            >
              Nürnberg Hummelstein {`  `}
            </a>
            <a
              href="https://marktschwaermer.de/de-DE/assemblies/12706"
              target="_blank"
              rel="noreferrer"
            >
              Nürnberg Stadtpark
            </a>
          </ThreeThirdsGrid>
        </TextWrapper>
      </Container>
      <Footer />
    </>
  )
}

export default IndexPage
