import styled from '@emotion/styled'

import { breakpoints, colors, space } from '../../utils/theme'

export const HeroImage = styled.img`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  object-position: center bottom;
  z-index: 2;
  top: 0;

  /* @media (max-width: ${breakpoints.s}px) {
    height: 50vh;
    position: relative;
  } */
`

export const HeroRightSide = styled.div`
  color: ${({ color }) => (color ? color : 'white')};
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;

  /* @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    height: 100%;
    bottom: 0;
    top: 0;
    position: relative;
  } */
`

export const CaptionBackground = styled.div`
  background-color: ${colors.backgroundColor};
  padding: ${space[8]};
  position: absolute;
  bottom: 0;
  color: white;

  a {
    color: white;
  }

  @media (max-width: ${breakpoints.s}px) {
    padding: ${space[4]};
  }

  /* @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    height: 100%;
    padding: 0rem;
    position: relative;
    margin-top: -33%;
  } */
`

export const LogoWrapepr = styled.div`
  cursor: pointer;
`
