import styled from '@emotion/styled'

import { breakpoints } from '../../utils/styles'
import { colors, fontSizes, space } from '../../utils/theme'
import paperTexture from '../../images/card-back-texture.jpg'

export const Space = styled.div`
  width: 100%;
  height: 100%;
  perspective: 600px;

  @media (max-width: ${breakpoints.m}px) {
    height: 400px;
  }
`

export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  box-shadow: 10px 10px 25px -12px rgba(0, 0, 0, 0.3);
`

export const CardFront = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: white;
  ${({ frontBackgroundImage }) =>
    frontBackgroundImage
      ? `background-image: url(${frontBackgroundImage});`
      : null};
  background-size: cover;
`

export const CardBack = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: rotateY(180deg);
  background-color: ${colors.backgroundColor};
  background-image: url(${paperTexture});
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${colors.backgroundColor};
  overflow: scroll;
  color: white;
`

export const Title = styled.h3`
  color: ${({ color }) => (color ? color : 'black')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  font-size: ${fontSizes['4xl']};
  margin: 0;
`

export const Description = styled.p`
  margin: ${space[6]};
  color: ${({ color }) => (color ? color : 'inherit')};
  line-height: 1.6;
  font-size: ${fontSizes.md};

  @media (max-width: ${breakpoints.s}px) {
    font-size: ${fontSizes.xs};
  }
`
