import * as React from 'react'

import Card from '../Card'
import { useWindowDimensions } from '../../utils/hooks'
import {
  Grid,
  GridItem1,
  GridItem2,
  GridItem4,
  GridItem5,
  GridItem6,
  GridItem7,
} from './styles'
import { breakpoints, space } from '../../utils/theme'
import kreationen from '../../images/Date_My_Choco_2020-07-09_8074.jpg'
import macaronGlace from '../../images/Macaron_Cake_2020-06-12_7497.jpg'
import eisAmStiel from '../../images/Blaue_Nacht_2020-04-30_6709.jpg'
import events from '../../images/Skoda_Fuerth_2019-05-19_0592_B.jpg'
import windbeutel from '../../images/windbeutel.jpg'
import cafe from '../../images/cafe.jpg'

const PhotoGrid = () => {
  const { width } = useWindowDimensions()

  return (
    <Grid
      gap={width > breakpoints.l ? space[12] : space[6]}
      style={{ marginBottom: space[40] }}
    >
      <GridItem1>
        <Card frontBackgroundImage={macaronGlace} title="Macaron glacé">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum.
        </Card>
      </GridItem1>

      <GridItem2>
        <Card title="Eis am Stiel" frontBackgroundImage={eisAmStiel}>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum.
        </Card>
      </GridItem2>

      <GridItem4>
        <Card title="Gläser, groß und klein" frontBackgroundImage={cafe}>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum.
        </Card>
      </GridItem4>

      <GridItem5>
        <Card title="Ice Cream Sandwich" frontBackgroundImage={windbeutel}>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum.
        </Card>
      </GridItem5>

      <GridItem6>
        <Card title="Kreationen" frontBackgroundImage={kreationen}>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum.
        </Card>
      </GridItem6>

      <GridItem7>
        <Card title="Events" frontBackgroundImage={events}>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum.
        </Card>
      </GridItem7>
    </Grid>
  )
}

export default PhotoGrid
