import Image from 'gatsby-image'
import styled from '@emotion/styled'

import { breakpoints, space } from '../../utils/theme'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(13, 5vw);
  grid-gap: ${({ gap }) => (gap ? gap : space[5])};
  margin: ${space[10]} 0 ${space[10]} 0;

  @media (max-width: ${breakpoints.m}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : space[6])};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`

export const GridImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`

export const GridItem1 = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 5;
`

export const GridItem2 = styled.div`
  grid-column-start: 7;
  grid-column-end: 13;
  grid-row-start: 2;
  grid-row-end: 6;
`

export const GridItem3 = styled.div`
  grid-column-start: 9;
  grid-column-end: 13;
  grid-row-start: 3;
  grid-row-end: 10;
`

export const GridItem4 = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 9;
  grid-row-end: 13;
`

export const GridItem5 = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 5;
  grid-row-end: 9;
`

export const GridItem6 = styled.div`
  grid-column-start: 7;
  grid-column-end: 13;
  grid-row-start: 6;
  grid-row-end: 10;
`

export const GridItem7 = styled.div`
  grid-column-start: 7;
  grid-column-end: 13;
  grid-row-start: 10;
  grid-row-end: 14;
`
