import React from 'react'

import { LogoWrapepr } from './styles'

const Logo = ({ height = 4, onClick, color = '#d00b55' }) => (
  <LogoWrapepr onClick={onClick}>
    <svg height={height} viewBox="0 0 175.46 40.02">
      <path
        fill={color}
        d="M10.56,38.89H0v-16H10.26v1.81H1.94v5.22H9.39v1.86H1.94V37h8.62Z"
      />
      <path fill={color} d="M15.38,38.89h-2v-16h2Z" />
      <path
        fill={color}
        d="M23.07,39.06a8.56,8.56,0,0,1-1.74-.17,9,9,0,0,1-1.4-.4A11.43,11.43,0,0,1,18.85,38c-.3-.17-.54-.31-.72-.43L18,37.47l.89-1.71.21.13.86.47a9.92,9.92,0,0,0,1,.4A8.81,8.81,0,0,0,22,37,5.18,5.18,0,0,0,24.29,37a4.19,4.19,0,0,0,1.24-.5,3.16,3.16,0,0,0,.93-.89,2.16,2.16,0,0,0,.36-1.27,2.07,2.07,0,0,0-.54-1.49,5,5,0,0,0-1.42-1A14.37,14.37,0,0,0,23,31a13.11,13.11,0,0,1-2-.84A5.26,5.26,0,0,1,19.42,29a3,3,0,0,1-.66-2,4.12,4.12,0,0,1,.34-1.61,3.81,3.81,0,0,1,1-1.35,5,5,0,0,1,1.56-.92A6.68,6.68,0,0,1,25,22.89a12.11,12.11,0,0,1,1.23.25c.38.09.73.19,1,.29a6.47,6.47,0,0,1,.72.28l.21.09-.73,1.61-.2-.08a11.59,11.59,0,0,0-1.66-.51,6.07,6.07,0,0,0-3.08,0,3.12,3.12,0,0,0-1,.5,2.5,2.5,0,0,0-.6.72,1.83,1.83,0,0,0-.2.87,1.46,1.46,0,0,0,.3.93,3.05,3.05,0,0,0,.85.72,8.6,8.6,0,0,0,1.25.58l1.47.55a15,15,0,0,1,1.49.66,5.6,5.6,0,0,1,1.33.91,4.34,4.34,0,0,1,1,1.31,4.18,4.18,0,0,1,.37,1.85,4.62,4.62,0,0,1-.39,1.93,4.1,4.1,0,0,1-1.13,1.48,5.07,5.07,0,0,1-1.79.93A7.82,7.82,0,0,1,23.07,39.06Z"
      />
      <path
        fill={color}
        d="M47.14,38.89h-2V27.58l-.42.59-1.08,1.56-1.15,1.69c-.39.57-.77,1.12-1.13,1.66l-1,1.5-.78,1.16-.17.26-.2-.25c-.21-.26-.48-.64-.84-1.13s-.73-1-1.14-1.64-.85-1.22-1.29-1.87-.87-1.25-1.26-1.82l-1-1.49-.11-.16V38.89H31.57V21.66l.42.62c.27.41.61.91,1,1.49l1.33,1.91,1.48,2.08,3.53,5,.52-.75,1.2-1.72,1.49-2.12,1.58-2.25c.51-.73,1-1.41,1.46-2.05l1.12-1.55.42-.59Z"
      />
      <path
        fill={color}
        d="M63.35,38.89h-2l-.06-.14c-.29-.67-.64-1.49-1-2.47s-.76-1.91-1.16-3H53.44c-.4,1-.79,2-1.16,3s-.73,1.8-1,2.47l-.06.14h-2l.14-.33q.32-.78.87-2.07c.36-.87.75-1.81,1.17-2.84l1.31-3.21c.45-1.11.88-2.19,1.31-3.22s.8-2,1.16-2.83l.88-2.09.22-.51.21.52c.22.53.51,1.23.87,2.08s.76,1.8,1.18,2.83l1.31,3.21c.46,1.11.9,2.19,1.32,3.22s.81,2,1.17,2.84S63,38,63.2,38.56Zm-9.23-7.3h4.29c-.39-1-.76-1.91-1.12-2.8s-.73-1.82-1-2.5h0l0,0c-.28.66-.62,1.5-1,2.48S54.5,30.61,54.12,31.59Z"
      />
      <path
        fill={color}
        d="M79.09,40l-.41-.5c-.42-.51-.93-1.11-1.55-1.81s-1.28-1.46-2-2.25L72.91,33c-.76-.84-1.5-1.65-2.21-2.45s-1.36-1.55-2-2.24-1-1.25-1.41-1.74V38.89H65.4V21.72l.41.53c.41.52.93,1.14,1.54,1.84s1.27,1.43,2,2.22l2.21,2.41,2.18,2.41c.71.78,1.37,1.51,2,2.2s1.05,1.23,1.44,1.72V22.88h1.94Z"
      />
      <path
        fill={color}
        d="M88.77,39.06a6.23,6.23,0,0,1-2.27-.4,5.4,5.4,0,0,1-1.83-1.12,5.13,5.13,0,0,1-1.22-1.79A6.21,6.21,0,0,1,83,33.36V22.88H85V33.3a4.35,4.35,0,0,0,.29,1.61A3.57,3.57,0,0,0,86,36.13a3.63,3.63,0,0,0,1.21.77,4.22,4.22,0,0,0,3,0,3.63,3.63,0,0,0,1.21-.77,3.83,3.83,0,0,0,.81-1.22,4.35,4.35,0,0,0,.29-1.61V22.88h2V33.36a6.21,6.21,0,0,1-.44,2.39,5.09,5.09,0,0,1-1.24,1.79A5.6,5.6,0,0,1,91,38.66,6.23,6.23,0,0,1,88.77,39.06Z"
      />
      <path
        fill={color}
        d="M100.41,38.89h-2v-16h9.37v1.83h-7.42v5.42H107V32h-6.55Z"
      />
      <path
        fill={color}
        d="M121.61,38.89h-2l-.06-.14c-.29-.67-.63-1.49-1-2.47s-.76-1.91-1.17-3H111.7c-.4,1-.79,2-1.17,3s-.73,1.8-1,2.47l-.06.14h-2l.13-.33.87-2.07c.36-.87.76-1.81,1.18-2.84l1.3-3.21,1.31-3.22,1.16-2.83.89-2.09.22-.51.21.52c.21.53.5,1.23.87,2.08s.75,1.8,1.17,2.83l1.32,3.21c.46,1.11.89,2.19,1.32,3.22s.81,2,1.16,2.84.65,1.55.88,2.07Zm-9.23-7.3h4.28l-1.11-2.8c-.39-1-.74-1.82-1-2.5h0v0c-.28.66-.63,1.5-1,2.48S112.76,30.61,112.38,31.59Z"
      />
      <path
        fill={color}
        d="M135.7,38.89h-2.46l-.07-.09c-1.09-1.38-2.13-2.72-3.14-4l-3-3.83-.6.58-.74.67c0,1.1,0,2.16,0,3.18s0,2.14,0,3.27v.24h-2.1v-16h2.1v6.75c1.14-1.08,2.27-2.18,3.36-3.31s2.27-2.27,3.43-3.38l.07-.06h2.56l-.39.39-3.18,3.24q-1.51,1.54-3.06,3.08c1.17,1.49,2.31,3,3.38,4.39s2.28,3,3.49,4.53Z"
      />
      <path
        fill={color}
        d="M142.14,38.89h-2V24.76h-4.09V22.88h10.19v1.88h-4.13Z"
      />
      <path
        fill={color}
        d="M154.71,39.06a6.19,6.19,0,0,1-2.27-.4,5.31,5.31,0,0,1-1.83-1.12,5.13,5.13,0,0,1-1.22-1.79,6.06,6.06,0,0,1-.45-2.39V22.88h1.95V33.3a4.35,4.35,0,0,0,.29,1.61,3.39,3.39,0,0,0,2,2,4.22,4.22,0,0,0,3,0,3.63,3.63,0,0,0,1.21-.77,3.72,3.72,0,0,0,.81-1.22,4.35,4.35,0,0,0,.29-1.61V22.88h1.95V33.36a6.25,6.25,0,0,1-.44,2.39,5.18,5.18,0,0,1-1.24,1.79A5.55,5.55,0,0,1,157,38.66,6.14,6.14,0,0,1,154.71,39.06Z"
      />
      <path
        fill={color}
        d="M175.46,38.89h-2.35l-.07-.11c-.12-.17-.27-.42-.46-.72l-1.37-2.22-.77-1.25-.7-1.15-.08-.12-.42-.68-.34,0h-2.58v6.23h-1.94v-16h4a6.78,6.78,0,0,1,2,.28A5.19,5.19,0,0,1,172,24a4.46,4.46,0,0,1,1.19,1.49,4.71,4.71,0,0,1,.45,2.13,5.73,5.73,0,0,1-.61,2.59A4,4,0,0,1,171.25,32l.4.66.71,1.19.82,1.33c.28.47.55.91.82,1.33l.71,1.15.52.83Zm-9.14-8.11h2.08a3.74,3.74,0,0,0,1.64-.32,3.11,3.11,0,0,0,1-.8,2.78,2.78,0,0,0,.52-1,3.39,3.39,0,0,0,.16-1,2.82,2.82,0,0,0-.83-2,2.92,2.92,0,0,0-1-.67,3.89,3.89,0,0,0-1.46-.25h-2.1Z"
      />
      <path
        fill={color}
        d="M49,11.73H45.88V1h2.64a6.71,6.71,0,0,1,2.72.49,5.35,5.35,0,0,1,1.84,1.28,5,5,0,0,1,1,1.76,6.12,6.12,0,0,1,.32,1.91A5.32,5.32,0,0,1,54,8.62a5,5,0,0,1-1.18,1.67,5.15,5.15,0,0,1-1.71,1.07A6,6,0,0,1,49,11.73Zm-1.61-1.48h1.51A4.5,4.5,0,0,0,50.49,10a3.57,3.57,0,0,0,1.26-.76A3.62,3.62,0,0,0,52.6,8a4.24,4.24,0,0,0,.3-1.64,4,4,0,0,0-.29-1.55A3.39,3.39,0,0,0,51.8,3.6a3.75,3.75,0,0,0-1.28-.82,4.56,4.56,0,0,0-1.7-.3H47.41Z"
      />
      <path fill={color} d="M58.66,11.73H57.13V1h1.53Z" />
      <path
        fill={color}
        d="M69.28,11.73H62.11V1h7V2.42H63.62V5.6h4.89V7.06H63.62v3.21h5.66Z"
      />
      <path
        fill={color}
        d="M84.75,11.73H82.84l-.09-.11c-.71-.9-1.4-1.78-2-2.63L78.83,6.58l-.3.29-.44.41v2c0,.69,0,1.41,0,2.15v.28H76.49V1H78.1V5.26c.7-.67,1.36-1.32,2-2s1.5-1.51,2.25-2.22L82.43,1h2L84,1.45,81.91,3.57,80,5.52l2.16,2.79c.73,1,1.49,2,2.28,3Z"
      />
      <path fill={color} d="M93.39,11.73H86.45V1H88v9.26h5.43Z" />
      <path
        fill={color}
        d="M102.75,11.73H95.58V1h7V2.42H97.1V5.6H102V7.06H97.1v3.21h5.65Z"
      />
      <path fill={color} d="M106.91,11.73h-1.53V1h1.53Z" />
      <path
        fill={color}
        d="M119.57,12.68l-.48-.58-1-1.19c-.4-.46-.84-.95-1.31-1.47s-1-1.06-1.46-1.61l-1.45-1.61-1.28-1.46-.71-.87v7.84h-1.52V0l.49.62c.27.35.61.75,1,1.2s.84.94,1.31,1.45l1.45,1.59L116,6.44l1.3,1.44c.28.32.52.61.73.87V1h1.52Z"
      />
      <path
        fill={color}
        d="M130.19,11.73H123V1h7V2.42h-5.46V5.6h4.89V7.06h-4.89v3.21h5.66Z"
      />
      <rect fill={color} x="138.63" y="5.9" width="36.42" height="0.87" />
      <rect fill={color} x="0.18" y="5.9" width="36.42" height="0.87" />
    </svg>
  </LogoWrapepr>
)

export default Logo
