import styled from '@emotion/styled'

import { breakpoints, colors, space } from '../utils/theme'

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${colors.backgroundColor};
  color: white;
  padding: ${space[6]};
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(24, 6vw);
  grid-gap: ${({ gap }) => (gap ? gap : space[6])};
  margin: ${space[40]} 0 ${space[40]} 0;

  @media (max-width: ${breakpoints.xl}px) {
    display: block;

    & > * {
      margin-bottom: ${({ gap }) => (gap ? gap : space[6])};
    }

    & > :last-child {
      margin-bottom: 0rem;
    }
  }
`
